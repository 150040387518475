/* Additional Styles that couldnt be applied with Styled Components*/

hmtl, body {
  font-family: 'Roboto', sans-serif;
  background: white;
  margin: 0 !important;
  padding: 0 !important;
  min-width: 750px;
  overflow: auto;
  width: 100vw;
  height: 100vh;
}

:root {
  width: 100vw;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #73859D;
  border: 0px none #ffffff;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #5e7087;
}
::-webkit-scrollbar-thumb:active {
  background: #16335B;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #d9d9d9;
}
::-webkit-scrollbar-track:active {
  background: #bfbfbf;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.bp3-input-group.bp3-large > .bp3-icon {
  margin: 0px;
  padding: 12px;
  color: white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #73859D;
}
.bp3-input-group.bp3-large .bp3-input:not(:first-child) {
  padding-left: 50px;
}

.bp3-spinner-head {
  stroke:  #FF9D66!important;
}

.react-grid-item.react-grid-placeholder {
  background-color: #16335B;
}

.react-draggable-dragging {
  cursor: move!important;
}

.react-draggable-dragging .dropping {
  cursor: move!important;
}

.droppable-element:default {
  cursor: move!important;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid #16335B;
  border-bottom: 2px solid #16335B;
  z-index: 10000;
}

iframe {
  width: 100%;
  height: 100%;
}

.name-edit {
  border: 2px solid grey;
}
.name-edit input{
  background: #16335B!important;
  text-align: center;
}
.name-edit .bp3-editable-text-content{
  background: #16335B!important;
}
.name-edit.bp3-editable-text.bp3-editable-text-editing::before {
  box-shadow: none!important;
  background-color: #16335B!important;
}

.name-no-edit input{
  background: #16335B!important;
  text-align: center;
}
.name-no-edit .bp3-editable-text-content{
  background: #16335B!important;
}
.name-no-edit.bp3-editable-text.bp3-editable-text-editing::before {
  box-shadow: none!important;
  background-color: #16335B!important;
}

.bp3-editable-text-content {
  min-width: auto!important;
}

.custom-popover .bp3-popover-content .bp3-menu {
  background: #16335B!important;
  color: white!important;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-popover .bp3-popover-content {
  background: none!important;
}

.custom-popover.bp3-popover .bp3-popover-arrow-fill {
  fill: #16335B!important;
}

.numeric-input {
  align-items: center!important;
}
.numeric-input .bp3-input-group .bp3-input {
  /* max-width: 60px; */
  background: none;
  border: 2px solid #16335B;
  color: #16335B;
  font-size: 16px;
}
.numeric-input .bp3-button-group .bp3-button {
  background: #16335B;
}
.numeric-input .bp3-button-group .bp3-button .bp3-icon {
  color: white;
}
.numeric-input .bp3-button-group {
  height: 30px;
}

.custom-switch {
  margin-bottom: 0px;
}
.bp3-control.bp3-switch.custom-switch input:checked ~ .bp3-control-indicator {
  background: #16335B!important;
}

.header-buttons .bp3-button-text {
  display: flex;
  align-items: center;
}

.vertical-buttons .bp3-button-group {
  width: 100%!important;
}

.horizontal-buttons .bp3-button-group {
  height: 100%;
}

.custom-collapse .bp3-button-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@keyframes blink {
  50% {
    opacity: 0;
    filter: blur(2px)
  }
}

@keyframes blink2 {
  0% {
    opacity: 0;
    filter: blur(2px)
  }
}

.bp3-tag.bp3-intent-primary {
  background: #FF9D66;
  color: white;
}

.map-annotation-button {
  display: flex!important;
  justify-content: center!important;
}

.react-json-view {
  height: auto!important;
  min-height: 100%!important;
  padding: 10px;
}

.components-tooltip {
  transform: translateX(207px);
}
